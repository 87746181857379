/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 *
 *  http://ionicframework.com/docs/theming/
 *
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// important.scss for any required enforcements to scss
@import 'theme/important.scss';

// custom styles
@import 'theme/colors.scss';
@import 'theme/typography.scss';
@import 'theme/font.scss';
@import 'theme/input.scss';

// component layout styles
@import 'theme/layout/card.scss';
@import 'theme/layout/navigation.scss';
@import 'theme/layout/button.scss';
@import 'theme/layout/material.design.scss';
@import 'theme/layout/search-program-card.scss';
@import 'theme/layout/grid.scss';
@import 'theme/layout/dropdown.scss';
@import 'theme/layout/modal.scss';
@import 'theme/layout/summary.scss';
@import 'theme/layout/sidebar.scss';
@import 'theme/layout/toolbar.scss';
@import 'theme/layout/alert.scss';

// Variable overrides first
$prefix: 'b-';

// Then import Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap';

// star rating component
@import '../node_modules/css-star-rating/scss/star-rating';

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import './app/app.scss';

ion-modal {
    --border-radius: 8px !important;
}
