.summary-header {
  font-size:1.1rem;
  text-align:center;
  font-family: "Avenir Lt Std 85 Heavy";
}

.summary-list {
    background: var(--ion-color-light-teal-2);
    color: var(--ion-color-text-teal);

    border: 1px solid var(--ion-color-neon-teal);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 85%;
    margin: auto;

    ion-item {
      --background: var(--ion-color-light-teal-2);
  }

  @media screen and (max-width: 576px) {
    width: 98%;
    margin-bottom: .5em;
  }
}

.summary-tab {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 5px;
    z-index: 1;
    width: 87%;
    border: 1px solid var(--ion-color-neon-teal);
    margin: auto;

    h3 {
      padding-left: 3em;
      font-size: 1.1em;
      text-align: center;
    }

    @media screen and (max-width: 576px) {
      width: 99%;
    }

  }
  .summary-arrow {
    color: var(--ion-color-teal);
  }
