.datePicker {
    margin-top: 15px;
    font-size: 0.8rem;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid var(--ion-color-dark-teal-2);
    border-radius: 10px;
    margin-bottom: 20px;
    width: 17.9em;
    height: 2.6em;

    @media screen and (max-width: 540px) {
      width: 82%;
    }
  }