// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/



/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #e86e00;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;


  /** secondary **/
  --ion-color-secondary: #12B2C4;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  --ion-color-secondary-text: #347781;
  --ion-color-secondary-background: #F2FEFF;
  --ion-color-secondary-card-background: linear-gradient(to left, rgb(255, 255, 255), #2ad6e852 87%, #0dd4ea40 1%);
  --ion-color-secondary-content-background: linear-gradient(to top, rgba(246, 254, 255, 0.79), #4fd2e052 87%, #0cc7db3b 1%);
  --ion-color-secondary-card-background: #dffbfee7;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #32783B;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #992329;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** light **/
  --ion-color-disabled: #B3BAC5;
  --ion-color-disabled-rgb: 244, 244, 244;
  --ion-color-disabled-contrast: #000000;
  --ion-color-disabled-contrast-rgb: 0, 0, 0;
  --ion-color-disabled-shade: #d7d8da;
  --ion-color-disabled-tint: #f5f6f9;

  /** AMO v4 Font Color, Jay Font */
  --ion-color-font: #2C6E75;
}

// old ionic 3 color schemes

// $colors: (
//   primary:    #e86e00,
//   secondary:  #11b3c4,
//   danger:     #992329,
//   light:      #f4f4f4,
//   dark:       #222,
//   textDark:   #555555,
//   success:    #32783B,
//   gray1:      #C8C9C1,
//   gray2:      #9B9B9B,
//   gray3:      #5E6A6E,
//   gray4:      #B3BDBC,
//   bordergray: #E0EAEF,
//   ion-border: #dedede,
//   labelText: #999,
//   uploaded: #417505,
//   noUpload: #D0021B,
// );