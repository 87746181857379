.sc-ion-card-md-h {
    border-radius: 10px;
}

.user-profile-card {
    --background: white;
}

/** Login/Signup Card **/

.parentCard {
    --background: white;
    width: 60%;
    margin-right: auto;
    margin-left: auto;

    // top: 50%;
    @media screen and (max-width: 992px) {
        width: 100%;
        // top: 5%;
    }
}

.formCard {
    margin: auto;
    background-color: white !important;
    border-radius: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;

    @media screen and (min-width: 768px) {
        width: 650px;
        align-content: center;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
        border-radius: 10px;
        background-color: white !important;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
        border-radius: 10px;
        background-color: white !important;
    }
}

.profile-form-card {
    margin: auto;
    background-color: white !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;

    @media screen and (min-width: 768px) {
        width: 770px;
        align-content: center;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
        border-radius: 3px;
        background-color: white !important;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
        border-radius: 3px;
        background-color: white !important;
    }
}

.loginCard {
    margin-top: 8em;

    @media screen and (max-width: 768px) {
        margin-top: 0em;
    }

    @media screen and (max-width: 450px) {
        margin-top: 0em;
    }
}

.mfa-card {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8em;
    background-color: white !important;
    border-radius: 7px;
    position: relative;

    @media screen and (max-width: 1199px) {
        width: 100%;
        height: 100vh;
        margin-top: unset;
        margin: unset !important;
        border-radius: unset !important;
        background-color: white !important;
    }
}

.signupCard {
    margin-top: 1em;

    @media screen and (max-width: 768px) {
        margin-top: 0em;
    }

    @media screen and (max-width: 450px) {
        margin-top: 0em;
    }
}

.activateCard {
    margin-top: 1em;

    @media screen and (max-width: 768px) {
        margin-top: 0em;
    }

    @media screen and (max-width: 450px) {
        margin-top: 0em;
    }
}

.formCard > ion-card-header {
    background-color: #fafeff;
    border-bottom: 2px solid #ccfaff;
}

.logoContainer {
    width: 100%;
    height: 110px;
    /* border: 1px solid purple; */
    background-color: transparent;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
        margin-bottom: 0px;
    }
}

/** Card ion-item styling with bottom border **/

:host {
    ion-item {
        border-bottom: none;
        background-color: #fafeff;
    }
}

.formCardTitle {
    font-family: 'Avenir LT Std 65 Medium';
    font-size: 1.2em;
    margin-left: 0px;
    border-bottom: none;
    color: #0b6d78;
    cursor: pointer;
}

item-label {
    background-color: #fafeff;
}

/**end Card ion-item styling**/

/**Card styling internal content **/
.formContent {
    align-content: center;
    margin: auto;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    @media screen and (min-width: 500px) and (max-width: 768px) {
        width: 90%;
    }

    @media screen and (min-width: 768px) {
        width: 485px;
    }
}

.lastItem {
    margin-bottom: 20px;
}

.entryLabel {
    color: var(--ion-color-dark-teal-2);
    // font-size:1.1rem;
}

/** end Card styling internal content **/

/** field label styling **/

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
    overflow: visible;
    white-space: normal;
    // color:#000;
    font-size: 1em;
}

.label-fix {
    font-size: 0.9em !important;
}

.predictive {
    padding-left: 15px !important;
}

.padding-fix {
    margin-bottom: 20px;
}

.label-border-fix {
    border-bottom: none !important;
    padding-top: 10px;
}

// Base Cards eg: dashboard and pending
.card-1 {
    --background: var(--ion-color-light-teal-1);
    -webkit-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
    -moz-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
    box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
    border: 1px solid var(--ion-color-gray-teal-1);

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 0em;
    }
}

.card-no-shadow {
    box-shadow: none !important;
}

.apply-card {
    --background: var(--ion-color-white);
    width: 30em;
    margin: auto;
    margin-top: 6em;
    -webkit-box-shadow: 1px 2px 4px 3px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 1px 2px 4px 3px rgba(0, 0, 0, 0.16);
    box-shadow: 1px 2px 4px 3px rgba(0, 0, 0, 0.16);

    @media screen and (max-width: 991px) {
        width: 30em;
        margin-top: 0em;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 0em;
    }
}

///Dashboard card container for content blocks
.card-container {
    width: 75%;
    margin: auto;

    @media screen and (max-width: 992px) {
        display: inline-grid;
        width: 100%;
    }
}

.intro {
    margin: auto;
    width: 77%;
    display: inline-flex;
    border-radius: 10px;
    background: var(--ion-color-light-teal-2);
    color: var(--ion-color-text-teal);
    padding: 1em;

    img {
        border-radius: 10px !important;
        border: 2px solid var(--ion-color-dark-teal-2);
        border-radius: 10px;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    p {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 10px;
    }

    @media screen and (max-width: 992px) {
        display: inline-grid;
        width: 100%;
    }
}

.clearfix {
    overflow: auto;
}
