.modal-header-color {
    --background: var(--ion-color-light-blue-2) !important;
    --border-color: transparent !important;
    box-shadow: 4px 3px px -2px rgba(128, 172, 255, 0.404), 4px 2px 2px 4px rgba(128, 172, 255, 0.404), 4px 1px 5px 4px rgba(128, 172, 255, 0.404);
}

.modal-title {
    --color: var(--ion-color-dark-blue);
    padding-left: 4em;
    font-family: 'Avenir Lt Std 85 Heavy';
    font-size: 1.3em;
}

.modal-content {
    width: 100%;
    overflow: auto;
}

.form-title {
    color: var(--ion-color-dark-teal-2);
}

.country-modal {
    h3 {
        padding-left: 0.5em;
    }

    ion-header.header-md:after {
        background-image: unset !important;
    }

    ion-header.header-ios:after {
        background-image: unset !important;
    }

    .country-toolbar {
        border-bottom: 2px solid #ccfaff;
    }

    ion-content {
        --background: var(--ion-color-white);
        --color: var(--ion-color-dark-teal-2);
    }
}
