ion-input {
  --placeholder-color: var(--ion-color-gray-teal-5);
  --color: var(--ion-color-text-teal);
  --padding-start: 8px !important;
}

ion-select {
  --placeholder-color: var(--ion-color-gray-teal-5);
  --color: var(--ion-color-text-teal);
  --padding-start: 8px !important;
}

.basic-inputs {
  border: 1px solid var(--ion-color-dark-teal-2);
  border-radius: 10px;
  padding-top: 8px;
  height: 2.4375em;
  margin-bottom: 0.5em !important;
  background-color: var(--ion-color-white);

  @media screen and (max-width: 582px) {
    width: 100%;
  }
}

.basic-select {
  border-radius: 10px;
  border: 1px solid var(--ion-color-dark-teal-2);
  height: 2.4375em;
  background-color: var(--ion-color-white);

  @media screen and (max-width: 375px) {
    width: 100%;
  }
}

.basic-textarea {
  border-radius: 10px;
  border: 1px solid var(--ion-color-dark-teal-2);
  padding-left: .5em;
}

.sc-ion-item-md-h.item-label-floating .sc-ion-item-md-s > ion-select,
.sc-ion-item-md-h.item-label-stacked .sc-ion-item-md-s > ion-select {
  width: 18.4375em;

  @media screen and (max-width: 375px) {
    width: 95%;
  }
}

.input-message {
  font-size: 0.75em;
}


.input-error-icon {
  position: absolute;
  right: 10%;
  color: var(--ion-color-danger);
}

.label-stacked.sc-ion-label-md-h {
  margin-bottom: 10px !important;
}

.label-stacked.sc-ion-label-ios-h {
  font-size: 0.6875em;
  margin-bottom: 10px !important;
}
// ***********************************************************************************
// moved z index lower to allow intl phone npm module css be on top of ion input z index.
// Intl phone module is being used in signup and profile pages.
// change back to level 2 if ion inputs are not showing correctly and find a different way to change intl phone country-list class
.sc-ion-input-md-h {
  z-index: 1;
}
.sc-ion-input-ios-h {
  z-index: 1;
}
// ***********************************************************************************

// color for when a ion input has focus or tapped
.has-focus {
  -moz-box-shadow: 3px 5px 4px rgba(8, 86, 94, 0.4);
  -webkit-box-shadow: 3px 5px 4px rgba(8, 86, 94, 0.4);
  box-shadow: 3px 5px 4px rgba(8, 86, 94, 0.4);
  background: var(--ion-color-gray-teal-2) !important;
}

.item-has-focus .label-stacked {
  color: var(--ion-color-dark-teal-2) !important;
}

.mat-datepicker-toggle-active {
  color: var(--ion-color-dark-teal-2);
}

.mat-calendar-body-selected {
  background-color: var(--ion-color-dark-teal-2);
}

.date-picker-container {
  width: 100%;
}

.form-title {
  --color: var(--ion-color-text-teal);
}

