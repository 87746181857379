* {
    font-size: 16px;
}

a {
    font-size: 1em;
}

i {
    font-size: 1em;
    @media screen and (max-width: 992px) {
        font-size: 0.75em;
    }
}

ion-card-header {
    font-family: 'Avenir LT Std 65 Medium';
    // --color: var(--ion-color-primary-shade);
}

ion-text {
    font-size: 1em !important;
    font-family: 'Avenir LT Std 65 Medium';
    --color: var(--ion-color-text-teal);
}

ion-label {
    font-family: 'Avenir LT Std 95 Black';
}

h1 {
    font-size: 2em;
    font-family: 'Avenir LT Std 95 Black';
}

h2 {
    font-family: 'Avenir LT Std 95 Black';
}

.page-title {
    font-size: 1.5em;
    font-family: 'Avenir LT Std 95 Black';

    @media screen and (max-width: 992px) {
        font-size: 1.25em;
    }
}

.sub-header {
    font-size: 1.375em;
    font-family: 'Avenir LT Std 95 Black';

    @media screen and (max-width: 992px) {
        font-size: 1.1em;
    }
}

.top-title {
    margin-top: 1.5em;
    margin-bottom: 0.5em;

    @media screen and (max-width: 767px) {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.sub-title {
    font-size: 1.25em;
    font-family: 'Avenir LT Std 95 Black';

    @media screen and (max-width: 992px) {
        font-size: 1em;
    }
}

.section-title {
    font-size: 1.25em;
    font-family: 'Avenir LT Std 65 Medium';

    @media screen and (max-width: 992px) {
        font-size: 1em;
    }
}

.section-sub-title {
    font-size: 1.1em;
    font-family: 'Avenir LT Std 65 Medium';

    @media screen and (max-width: 992px) {
        font-size: 0.9em;
    }
}

.callout-text {
    font-size: 1em;
    font-family: 'Avenir LT Std 45 Book';

    @media screen and (max-width: 992px) {
        font-size: 0.75em;
    }
}

.function-title {
    font-family: 'Avenir Lt Std 85 Heavy';
    font-size: 1.1em;
}

.select-text {
    color: var(--ion-color-dark-teal-2);
    font-family: 'Avenir Lt Std 65 Medium';
    font-size: 1em;
}

.note-text {
    font-size: 0.9rem;
}

//html body inherits unexpected properties, changed it to a class instead
.body {
    font-size: 1em !important;
    font-family: 'Avenir LT Std 65 Medium';
    -webkit-user-select: text;
    user-select: text;

    @media screen and (max-width: 992px) {
        font-size: 0.8em !important;
    }
}
// does not work with ion-label
.form-title {
    font-size: 0.6875em;
    font-family: 'Avenir LT Std 65 Medium';
}

.form-text {
    font-size: 0.875em;
    font-family: 'Avenir LT Std 55 Roman';
}

.card-text {
    font-size: 1em;
    font-family: 'Avenir LT Std 55 Roman';
}

.bold-text {
    font-size: 1em;
    font-family: 'Avenir LT Std 85 Heavy';
}

.bold-text-pricedrop {
    font-size: 0.9em;
    font-family: 'Avenir LT Std 85 Heavy';
    color: #d64541 !important;
}

.bold-text-pricedrop-expires {
    font-size: 0.8em;
    font-family: 'Avenir LT Std 55 Roman';
    color: gray !important;
}

.bold-text-line-through {
    font-size: 0.8em;
    opacity: 0.7;
    font-family: 'Avenir LT Std 55 Roman';
    // text-decoration: line-through;
    white-space: nowrap; /* would center line-through in the middle of the wrapped lines */
    position: relative;
}

.bold-text-line-through:after {
    border-top: 1.2px solid #d64541;
    position: absolute;
    content: '';
    right: 0;
    top: 35%;
    left: 0;
}

.bold {
    font-family: 'Avenir LT Std 85 Heavy' !important;
}

.center {
    text-align: center;
}

.center-block {
    text-align: center;
    margin: auto;
}

.inline {
    display: inline;
}

.advisor-title {
    font-size: 1.2em;
    font-family: 'Avenir LT Std 85 Heavy';
}
// too big, test on broswerstack.com
.global-nav-bar {
    font-size: 1.25em !important;
    font-family: 'Avenir LT Std 55 Roman' !important;

    ion-icon {
        vertical-align: middle;
    }
}

.nav-bar-header-btn {
    span {
        font-size: 14px !important;
        color: var(--ion-color-blue);
        &:hover {
            color: var(--ion-color-primary);
        }
    }
    &:hover {
        .mat-button-focus-overlay,
        .mat-button-ripple.mat-ripple,
        .mat-ripple {
            display: none;
        }
    }
    &.user-menu {
        text-transform: capitalize;
        .wrapper-menu {
            display: flex;
            width: 80px;
            height: 45px;
            justify-content: flex-end;
            align-items: center;
            border-radius: 22px;
            padding-right: 3.5px;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            fa-icon {
                margin-right: 6px;
                margin-top: 6px;
                color: var(--ion-color-medium-shade);
            }
            span {
                border-radius: 100%;
                background: var(--ion-color-primary);
                color: var(--ion-color-light); //white;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                img {
                    width: 40px;
                    border-radius: 100%;
                }
            }
        }
        &:hover {
            .wrapper-menu {
                fa-icon {
                    color: var(--ion-color-primary);
                }
            }
        }
    }
}
.mat-menu-panel.logout-menu {
    margin-right: 1rem;
    width: 400px;
    .menu-logout-items {
        fa-icon {
            margin-right: 0.5rem;
        }
        .disabled {
            pointer-events: none;
            opacity: 0.6;
        }
        .line {
            width: 100%;
            margin: auto;
            height: 1px;
            background-color: var(--ion-color-light-shade);
        }
        ion-list {
            ion-item {
                ion-label {
                    color: var(--ion-color-dark-gray);
                    margin: 0;
                    &.title {
                        text-align: center;
                    }
                    &.bold {
                        font-weight: 600;
                        color: var(--ion-color-light-contrast);
                    }
                }
                span {
                    color: var(--ion-color-dark-gray);
                    fa-icon {
                        margin: 0 0 0 0.25rem;
                    }
                }
                fa-icon {
                    color: var(--ion-color-dark-gray);
                }
            }
        }
    }
}

.seat-number {
    color: var(--ion-color-secondary);
    font-family: 'Avenir LT Std 95 Black';
    font-size: 1em;
    // height: 1.2em;
}

.seat-text {
    font-size: 0.8em;
    color: var(--ion-color-dark-teal-1);
}

.text-center {
    text-align: center;
}

.sidebar-text {
    font-size: 0.85rem;
    font-family: 'Avenir LT Std 65 Medium';
    color: var(--ion-color-dark-blue);
}

.cancel-text {
    font-size: 0.8em;
    color: var(--ion-color-dark-orange-1);
    font-family: 'Avenir LT Std 55 Roman';
}

.cancel-link {
    font-size: 0.75em;
    font-family: 'Avenir LT Std 95 Black';
    text-decoration: none;
}

.link-anchor-base {
    color: var(--ion-color-primary);
    font-family: 'Avenir LT Std 85 Heavy';
    word-break: break-word;
    text-decoration: underline;
}

.link-anchor {
    font-size: 0.875em;
}

.small-text {
    font-size: 0.8rem;
}

.md-small-text {
    font-size: 0.85rem;
}

.small-box {
    max-width: 300px;
}

.mid-box {
    max-width: 320px;
}

.dashboard-text {
    font-size: 0.925em;
}

.highlight {
    background-color: var(--ion-color-light-blue-2);
    box-shadow: 0 3px 1px -2px rgba(128, 172, 255, 0.18), 0 2px 2px 0 rgba(128, 172, 255, 0.12), 0 1px 5px 0 rgba(128, 172, 255, 0.1);
    padding: 2px 0;
}

.summary-row-title {
    @media screen and (max-width: 1200px) {
        font-size: 0.9rem !important;
    }
}

.label-width {
    min-width: 40% !important;
    overflow: visible !important;
    // padding-right:20px;
}

.content-width {
    width: 45% !important;
    padding-left: 20px;
    overflow: visible !important;
}

.black-text {
    color: var(--ion-color-text-teal);
    --color: var(--ion-color-text-teal);
}
