.grecaptcha-badge {
    visibility: hidden !important;
}

.date-picker-box {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
}

.nav-anchor {
    text-decoration: none;
    color: var(--ion-color-blue);
    font-size: 0.9em;
    padding: 5px 13px;
}

.nav-anchor:hover {
    color: var(--ion-color-primary);
}

.amoLogo {
    margin: 0px auto;
    width: 97px;
    height: 40px;
    padding-top: 10px;
    // padding-left: 20px;
    display: inline-flex;

    @media screen and (max-width: 991px) {
        width: 100%;
        height: 32px;
        // padding-left: 2em;

        padding-top: 0px;
    }
}

.amoLogo-modal {
    margin: 0px auto;
    height: 40px;
    padding-top: 10px;
    // padding-left: 20px;
    display: inline-flex;

    @media screen and (max-width: 991px) {
        height: 40px;
        // padding-left: 2em;

        // padding-top: 0px;
    }
}

.md .amoLogoOffset {
    padding-right: 3em;
}

.md .amoLogoOffsetLeft {
    padding-left: 3em;
}

.tabs-container {
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.icon-cursor {
    cursor: pointer;
}

.popover-class .popover-content {
    bottom: 5% !important;
}

.context-menu {
    background-color: var(--ion-color-gray) !important;
    box-shadow: #424242;
    border: 1px solid lightblue;
    margin: 0.2rem;
}

// From program-apply component, this changes the width
// of Start Date alert selection width. The class is named in
// the program-apply ts file by the 'customAlertOptions' variable
// https://ionicframework.com/docs/api/alert
.time-selection {
    --min-width: 415px;

    @media screen and (max-width: 375px) {
        --min-width: 350px;
        --max-width: 360px;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    @media screen and (max-width: 320px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    .alert-radio-label.sc-ion-alert-md {
        white-space: pre-line !important;
        text-overflow: unset !important;
        padding-top: 11px !important;
        font-size: 14px !important;

        @media screen and (max-width: 411px) {
            font-size: 12px !important;
        }
    }

    .alert-radio-label.sc-ion-alert-ios {
        white-space: pre-line !important;
        font-size: 12px !important;
    }

    .alert-radio-label.sc-ion-alert-ios .alert-radio-label.sc-ion-alert-md {
        @media screen and (max-width: 375px) {
            --font-size: 0.8em !important;
        }
    }
}

.mobile-filter-selection {
    --min-width: 415px;

    @media screen and (max-width: 375px) {
        --min-width: 350px;
        --max-width: 360px;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    @media screen and (max-width: 320px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    .alert-checkbox-label {
        font-size: 0.8em !important;
    }

    .alert-checkbox-label.sc-ion-alert-md {
        padding-inline-start: 40px !important;
        -webkit-padding-start: 40px !important;
    }

    .alert-checkbox-label.sc-ion-alert-ios {
        padding-inline-start: 12px !important;
        -webkit-padding-start: 12px !important;
    }
}

.sortby-selection {
    --min-width: 350px;

    @media screen and (max-width: 375px) {
        --min-width: 350px;
        --max-width: 360px;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    @media screen and (max-width: 320px) {
        --min-width: 310px !important;
        --max-width: 320px !important;
    }

    .alert-radio-label.sc-ion-alert-ios .alert-radio-label.sc-ion-alert-md {
        @media screen and (max-width: 375px) {
            --font-size: 0.8em !important;
        }
    }
}

.housing-selection-alert {
    --min-width: 350px;

    .alert-tappable.sc-ion-alert-md {
        height: 100px;
    }

    .alert-radio-label.sc-ion-alert-md {
        white-space: pre-line !important;
    }
}

// From search, this changes the width
// of specialty alert selection width. The class is named in
// the program-apply ts file by the 'specialtyOptions' variable
// https://ionicframework.com/docs/api/alert
.specialty-search-alert {
    --min-width: 320px;
}

.location-search-alert {
    --min-width: 320px;
}

.tag-search-alert {
    --min-width: 320px;
}

// popover width for "Reach an advisor" button
//https://ionicframework.com/docs/api/popover#css-custom-properties
.contact-popover {
    --min-width: 250px;
}

.info-tooltip-popover {
    --min-width: 390px;
}

// search filters pop over for desktop
.filter-popup {
    .popover-viewport {
        width: 300px !important;
        max-height: 80vh !important;
    }

    .popover-content.sc-ion-popover-md {
        width: 300px;
    }
}

// select alert width
.profile-select-alert {
    --min-width: 280px;
}

.profile-acquisition-alert {
    --min-width: 280px;
}

.profile-fieldofstudy-alert {
    --min-width: 380px;
}

.help-links {
    font-size: 1em;

    .nav-icon {
        font-size: 1em;
        margin-right: 0.4em;
    }
}

.payment-options-modal {
    --max-height: 38em;
    --max-width: 33em;
    --background: var(--ion-color-light-teal-1);
    padding: 1em;

    @media screen and (max-width: 516px) {
        --max-height: unset;
        --max-width: unset;
        overflow: auto;
    }
}

.payment-notify-popup {
    --max-height: 27em;
    --max-width: 30em;
    --background: var(--ion-color-light-teal-1);
    padding: 1em;

    @media screen and (max-width: 516px) {
        --max-height: unset;
        --max-width: unset;
        overflow: auto;
    }
}

.custom-popover {
    .popover-content {
        border-radius: 5px;
        --width: 125px;

        .popover-viewport {
            ion-content {
                // --background: var(--ion-color-light-teal-1) !important;
                // color:white !important;
                --background: #11b3c4 !important;
                --color: #ffffff;
                text-align: center;

                div {
                    padding-bottom: 0.5em;

                    p {
                        font-size: 12px !important;
                        font-weight: lighter;
                        margin-bottom: 0;
                    }

                    .link {
                        font-weight: bold;
                        text-decoration: underline;
                        cursor: pointer;
                        color: #ffffff;
                        margin-bottom: 1em;
                        font-size: 0.75em !important;
                    }
                }
            }
        }

        &div::before {
            display: block;
            content: '';
            position: absolute;
            top: -10px;
            left: 45%;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 10px solid #fff;
        }
    }
}

.help-notify-popup {
    // --max-width: 285px !important;
    --width: 285px !important;
}

.itinerary-notify-popup {
    --max-width: 565px;
}

.iti-footer-list {
    margin-bottom: 1px !important;
}

.sidebar-modal-lg {
    --max-height: 32em;
    --max-width: 33em;
    --background: var(--ion-color-light-teal-1);
    padding: 1em;

    @media screen and (max-width: 516px) {
        --max-height: unset;
        --max-width: unset;
        overflow: auto;
    }
}

.sidebar-modal {
    --max-height: 27em;
    --max-width: 33em;
    --background: var(--ion-color-light-teal-1);
    padding: 1em;

    @media screen and (max-width: 516px) {
        --max-height: unset;
        --max-width: unset;
        overflow: auto;
    }
}

.sidebar-modal-md {
    --background: var(--ion-color-light-teal-1);
    --max-height: 28em;
    --max-width: 30em;
    padding: 1em;

    @media screen and (max-width: 516px) {
        --max-height: unset;
        --max-width: unset;
        overflow: auto;
    }
}

.sidebar-modal-sm {
    --background: var(--ion-color-light-teal-1);
    --max-height: 22em;
    --max-width: 33em;

    @media screen and (max-width: 516px) {
        --max-width: 19em;
        --max-height: 19em;
        overflow: auto;
    }
}

.sidebar-modal-xs {
    --max-width: 25em;
    --max-height: 24em;
    --background: var(--ion-color-light-teal-1);

    @media screen and (max-width: 516px) {
        --max-width: 19em;
        overflow: auto;
    }
}

.mat-mdc-button {
    letter-spacing: normal !important;
}

.temp-user-modal {
    --max-width: 25em;
    --max-height: 22em;
    --background: var(--ion-color-light-teal-1);

    @media screen and (max-width: 516px) {
        --max-width: 281px;
        --max-height: 333px;
        overflow: auto;
    }
}

app-sidebar-alert {
    overflow: auto !important;
}

// hide sneaky link downloads
.download-zip-link {
    visibility: hidden;
}

.warning-text {
    color: var(--ion-color-danger);
}

// from details page openPreview function
// sets the image slider modal background
.image-slider-modal {
    --background: var(--ion-color-blue-rgba);
}

.call-to-action-btn {
    text-decoration: underline;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.flex {
    display: inline-flex;
}

// inline icons padding next to text
.fa-line-padding {
    padding-right: 0.2em;
}

.move-it-up {
    margin-bottom: 3rem;
}

.anchor-text {
    cursor: pointer;
}

// .sc-ion-card-md-h {
//   overflow: visible !important;
// }

ion-select {
    border: 1px solid var(--ion-color-dark-teal-2);
}

.billing-list {
    background: var(--ion-color-light-teal-1);

    ion-item {
        --background: var(--ion-color-light-teal-1);
    }
}

.phone-label {
    font-weight: 300;
    font-size: 0.75em;
    color: var(--ion-color-text-teal);
    padding-left: 1.3em;
    padding-bottom: 0.2em;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.coupon-card {
    --background: white;
}

.col-padding {
    @media screen and (max-width: 700px) {
        padding-right: 10px;
    }
}

.wire-card {
    display: inline-flex;
    width: 89.5%;
    margin: 0 auto;
    --background: var(--ion-color-light-teal-2);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-bottom: 2em;
    border-radius: 1px solid var(--ion-color-secondary);

    @media screen and (max-width: 1200px) {
        display: inline-block;
    }

    @media screen and (max-width: 330px) {
        width: 100%;
    }
}

.card-errors {
    color: red;
    text-align: center;
}

.wire-col {
    width: 85%;
    margin: auto;
}

.card-number-input {
    padding: 10px;
    border: 1px solid var(--ion-color-dark-teal-2);
    margin-bottom: 0.95em;
    height: 2.5em;
}

.card-input-box {
    background: var(--ion-color-light-teal-1);
    color: var(--ion-color-text-teal);
    margin-left: 0.2em;
    margin-right: 0.2em;
    border: 1px solid var(--ion-color-gray-teal-1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.card-label {
    font-weight: 300;
    font-size: 0.75em;
    color: var(--ion-color-text-teal);
    padding-left: 0.1em;
    // padding-bottom: .2em;
    margin-bottom: 0.6em;
    text-align: left;
}

.coupon-button {
    margin-top: 2em;
    height: 2em;

    @media screen and (max-width: 576px) {
        margin-top: 0.5em;
    }
}

// .basic-inputs {
//   width: calc(100% - 20px);
//   height: 2.4375em;
//   margin: auto;
// }

.country-select {
    width: calc(100% - 20px);
    margin-left: 0.5em;
}

.commands-container {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.container {
    display: flex;
    justify-content: center;
}

.cube {
    width: 200px;
    height: 200px;
    background-color: #e74c3c;
}

input {
    width: 3rem;
}

select {
    width: 5rem;
}

.summary-tab {
    width: 90%;
    margin: auto;

    @media screen and (max-width: 576px) {
        width: 99%;
    }
}

.note-card-container {
    height: auto;
}

.note-card {
    border: 1px solid var(--ion-color-neon-teal);
    --background: var(--ion-color-white);
    min-height: 230px;
    margin-top: 2em;
    -webkit-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
    -moz-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
    box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
}

.card-text {
    padding-right: 3.5em;
    padding-left: 3.5em;
    font-size: 1.1em;
    padding-top: 1em;
}

ion-row {
    &.new-design {
        ion-text {
            .card-text {
                padding-right: 0 !important;
                padding-left: 0 !important;
                font-weight: 300;
                font-style: normal;
                font-size: 16px;
                color: #333333;
            }
        }
        span.card-text,
        a.card-text {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}

.card-header {
    width: 11em;
    background: var(--ion-color-light-teal-2);
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 2.3em;
    text-align: center;
    padding: 0.5em;

    i {
        padding-right: 0.2em;
    }
}

.next-card-container {
    .next-card {
        ion-text {
            p {
                a {
                    color: #0279bf !important;
                    &:hover {
                        color: #11b3c4 !important;
                    }
                }
            }
        }
    }
}

.payment-warning {
    max-width: 450px;
    margin: auto;
}

.stripe-input-short {
    width: 98%;
    border: 1px solid var(--ion-color-dark-teal-2);
    border-radius: 10px;
    padding-top: 8px;
    padding-right: 10px;
    height: 2.4375em;
    margin-bottom: 0.5em !important;
    background-color: var(--ion-color-white);
    color: var(--ion-color-text-teal);

    @media screen and (max-width: 582px) {
    }
}

.card-input {
    width: 100%;
}

.card-number-input {
    width: 100%;
}

.billing-input {
    width: 100%;
}

.stripe-list {
    --background: var(--ion-color-light-teal-1);
    margin-top: 1.7em;
}

.payment-title {
    width: 90%;
    margin: auto;
}

.modal-logo {
    margin-left: 2.3em;

    @media screen and (max-width: 576px) {
        margin-left: 1.9em;
    }
}

// search
.no-results {
    --background: var(--ion-color-secondary-card-background);
    --color: var(--ion-color-secondary-text);
}

.help-text {
    white-space: pre;
    font-weight: 300;
}

.stripe-month-alert {
    --min-width: 320px;
}

.datepicker-input {
    width: 100%;
    display: inline-flex;
    border: 1px solid var(--ion-color-dark-teal-2);
    border-radius: 10px;
    padding-left: 0.5em;
}

.hide {
    visibility: hidden;

    @media screen and (max-width: 991px) {
        display: none;
    }
}

.nav-display-none {
    display: none;
}

.helpIcon {
    color: #424242 !important;
}

.underline {
    text-decoration: underline;
}

ion-menu-button {
    color: #424242 !important;
}

ion-back-button {
    color: #424242 !important;
}

@media screen and (min-width: 420px) {
    .program-notify-popup {
        --max-width: 365px;
        --height: 365px;
    }
    // width needed to allow no text wrapping on desktop for availability dates. Fits largest month configuration
    .program-notify-popup-wide {
        // September (two digits), 2024. is the longest length we will see in this modal
        --max-width: 392px;
        --height: 365px;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

.loading-cards {
    border-radius: 0;
}

.loading-grid {
    width: 84vw;
}

.permalink-navbar {
    position: absolute;
    z-index: 100;
    top: 1%;
}

.warning-banner-bar {
    --background: var(--ion-color-warning-tint);
}

.bannerUpdateShow {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    top: 65px;
    right: 0;
    left: 0;
    z-index: 100000;

    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--ion-color-blue); //#36496d;
    padding: 10px;
    /* margin: 0px 10px; */
    border-radius: 3px;

    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
    }

    .icon {
        fa-icon {
            color: white;
            font-size: 25px;
        }
    }

    .banner-msg {
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ion-label {
            color: white;
            text-align: left;
            font-family: inherit;

            &:first-child {
                font-weight: 600;
            }
        }
    }
}

// allows scrolling for ion menus that the cursor is on
.menu-content-open {
    pointer-events: all !important;
}

.help-popover {
    --backdrop-opacity: 0;
}

.application-dropdown-list {
    --width: 517px;
    --max-width: 517px;

    .alert-radio-label {
        white-space: pre-line !important;

        @media screen and (max-width: 767px) {
            font-size: 0.75rem;
        }
    }
}

.my-custom-modal-css {
    .modal-wrapper {
        --height: 400px !important;
        position: absolute;
    }
}

.application-apply-success {
    &::part(content) {
        height: auto;
    }
}

.modal2-wrapper-450-450 {
    --width: 450px;
    --height: 450px;
}

.modal2-wrapper-450-60550 {
    --width: 450px;
    --height: 600px;
}

.modal2 {
    .header {
        height: 59px;
        background-color: #11b3c4;
        font-family: 'AvenirLTStd-Medium', 'Avenir LT Std 65 Medium', 'Avenir LT Std 45 Book', 'Avenir LT Std', sans-serif;
        font-weight: 650;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 59px;
    }

    .body {
        width: 75%;
        margin: 20px auto 25px auto;

        h1 {
            font-size: 14px;
            font-family: 'AvenirLTStd-Roman', 'Avenir LT Std 55 Roman', 'Avenir LT Std 45 Book', 'Avenir LT Std', sans-serif;
            color: #787878;
            margin: 0;
        }

        h2 {
            font-size: 12px;
            font-family: 'AvenirLTStd-Roman', 'Avenir LT Std 55 Roman', 'Avenir LT Std 45 Book', 'Avenir LT Std', sans-serif;
            color: #08565e;
            margin: 0;
        }

        h3 {
            font-size: 10px;
            font-family: 'AvenirLTStd-Roman', 'Avenir LT Std 55 Roman', 'Avenir LT Std 45 Book', 'Avenir LT Std', sans-serif;
            color: #787878;
            margin: 0;
        }

        .input {
            width: 100%;
            border-radius: 3px;
            border: 1px solid #c2cbcc;
            height: 36px;
            margin: 8px 0 8px 0;
        }

        .input-verify-width {
            width: 52%;
        }

        .footer-buttons {
            height: 50px;
            margin-top: 50px;

            .left {
                float: left;
            }

            .right {
                float: right;
            }
        }

        .footer-center-button {
            height: 50px;
            margin-top: 50px;
            text-align: center;
        }
    }
}

.default-btn {
    width: auto;
    min-width: 9em;
    --border-radius: 0px;
    font-size: 0.9em;
    height: 2.3em;
    cursor: pointer;
}

ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
    font-size: 32px;
    visibility: hidden;
}

.modal-single-option-selection {
    --max-width: 360px;
    // --height: 380px;
    --height: auto;
    --max-height: 380px;
    @media (max-width: 400px) {
        --max-width: 97%;
    }
}

.profile-item-input {
    --highlight-height: 0px;
    --background-focused: white;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --padding-start: 0px;
    --color: var(--ion-color-near-black);

    .profile-input-label {
        pointer-events: all;
        white-space: nowrap;
    }

    .profile-input-label-nm {
        margin-bottom: -5px !important;
    }

    button {
        background-color: var(--ion-color-white);
        height: 28px;
    }

    ion-icon {
        font-size: 20px;
    }

    .profile-input {
        --background: var(--ion-color-white);
        border: 1px solid var(--ion-color-gray-teal-3);
        border-radius: 3px;
        box-sizing: border-box;
        --background-focused: white;
        background: unset !important;
    }

    .profile-datepicker {
        width: 100%;
        position: relative;
        height: 40px;
    }

    mat-datepicker-toggle {
        position: absolute;
        right: 1%;
        color: var(--ion-color-teal);
        height: 38px;
    }

    .profile-input:hover {
        border-color: var(--ion-color-dark-teal-4) !important;
    }

    &.dob-item {
        button {
            background-color: var(--ion-color-white);
            height: 95% !important;
        }
    }
}

.profile-input-warning {
    border-color: var(--ion-color-warning-tint);
}

.profile-input-error-message {
    font-size: 0.8rem;
    text-align: right;

    ion-icon {
        vertical-align: text-bottom;
    }
}
ion-modal.document-viewer-modal {
    --width: 80vw !important;
    --height: 80vh !important;
}

ion-modal.multi-document-viewer-modal {
    --width: 95vw !important;
    --height: 80vh !important;
}

.immunization-modal {
    --height: 535px;
    --width: 550px;
}

.reset-password-modal {
    --height: 565px;
}
