.list-md {
    padding-top:0 !important;
    padding-bottom: 0 !important;
  }

.sidebar-list {
    // min-width: 248px;
    // padding-top: 5em;
    // min
}

.side-bar {
    color: var(--ion-color-text-teal);
}
.no-color-container {
    padding: 0.8em 0.6em;
    // padding-bottom:1em;

    .text-container {   
        padding-left: 1em;
    }
}
.sidebar-title {
    color: var(--ion-color-dark-teal-1);
    padding: .2em;
    font-size: 1.0rem;
}

.sidebar-subtitle {
    font-family: "Avenir Lt Std 85 Heavy";
    color: var(--ion-color-dark-blue-1);
    padding: .2em;
    font-size: 0.95rem;
}

.coach-image {
    border: 3px solid var(--ion-color-dark-teal-1);
    min-width: 4.2em;
    min-height: 4em;
}

.icon-envelope {
    padding-right: .2em;
}
.link-anchor {
    color: var(--ion-color-text-teal);
    font-family: 'Avenir LT Std 85 Heavy';
    word-break: break-word;
}
.light-blue-bg {
    background-color: var(--ion-color-light-teal-2);
}

.sidebar-title-container {
    background-color: var(--ion-color-light-blue-2);
    border-bottom: 2px solid var(--ion-color-light-blue-1);
    width: 100%;
    min-height: 2.4em;
    padding: .5em;
}

.application-select-container {
    margin-top: 1em;
    margin-left: 1.5em;
}

.drop-down-text {
    color: var(--ion-color-dark-teal-1);
    font-size: .7em;
    margin: 0px;
}

.button-text-color {
    text-decoration: underline;
    font-size: .8em;
    width: 11em;
}

.link-anchor {
    padding-left: 1em;
    word-break: break-word;
    text-decoration: underline;
    padding-left: 0px;
}