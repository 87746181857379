ion-button {
    text-transform: none;
    font-family: 'Avenir LT Std 65 Medium';

    i {
        margin-bottom: 2px;
    }
}

.header-button-row {
    display: inline-flex;
    float: right;
}

.half-button {
    max-height: 48px;
    min-width: 148px;
    font-size: 0.9em;
    font-family: 'Avenir LT Std 65 Medium';
}

.small-button {
    max-height: 35px;
    min-width: 100px;
    padding: 0.1em;
    font-size: 0.875em;
    font-family: 'Avenir LT Std 65 Medium';
}

.dashboard-button {
    --color: var(--ion-color-teal) !important;
    --padding-top: 1.5em;
    text-decoration: underline;
    // border-bottom: 1px solid var(--ion-color-teal)
}

.custom-document-button-incomplete {
    height: 3.5em;
    width: 14em;
    --opacity: 1 !important;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-teal);
    --background-focused: var(--ion-color-secondary-shade);
    // --background-activated: var(--ion-color-secondary-tint);
    --background-hover: var(--ion-color-teal) !important;
    // --border-color: var(--ion-color-secondary);
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        padding-right: 1px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        // width: 80%;
    }
}

.custom-document-button-complete {
    height: 3.5em;
    width: 14em;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-dark-teal-1) !important;
    --background-focused: var(--ion-color-secondary-shade) !important;
    --background-hover: var(--ion-color-dark-teal-2) !important;
    --background-activated: var(--ion-color-secondary-tint) !important;
    --border-color: var(--ion-color-secondary) !important;
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        padding-right: 1px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.blocked-button {
    height: 3.5em;
    width: 14em;
    --opacity: 1 !important;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-gray-teal-5);
    --background-hover: var(--ion-color-gray-teal-5) !important;
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        width: 80%;
    }
}

.btn-link-text {
    text-decoration: underline;
    font-size: 0.875em;
    line-height: 1.5 !important;
    font-family: 'Avenir Lt Std 85 Heavy';
    width: 225px;
    min-height: 32px;
}

.btn-link-button {
    // text-decoration: underline;
    // font-size: 0.875em;
    line-height: 1.5 !important;
    font-family: 'Avenir Lt Std 85 Heavy';
    height: 3.5em;
    width: 14em;
    --color: white;
    margin-bottom: 1em;
    --border-radius: 3px !important;
    --border-style: solid !important;
    --border-width: 1.8px !important;
    --box-shadow: none;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        width: 81%;
    }
}

ion-segment-button {
    --background-checked: var(--ion-color-white);
    --color-checked: var(--ion-color-primary);
    padding-right: 2em;
    padding-left: 2em;

    .segment-label {
        text-transform: capitalize;

        @media screen and (max-width: 576px) {
            font-size: 0.8em;
        }

        @media screen and (max-width: 320px) {
            font-size: 0.7em;
        }
    }

    @media screen and (max-width: 1024px) {
        padding-right: 0em;
        padding-left: 0em;
        width: 50% !important;
    }
}

.sm-button-text {
    font-size: 0.83em !important;
}

.future-status-label {
    text-decoration: underline;
}

.upload-btn {
    font-size: 0.9rem;
    text-decoration: underline;
    font-family: 'Avenir Lt Std 85 Heavy';
    width: 11em;
    height: auto;
    --color-hover: var(--ion-color-teal);
    text-wrap: balance;

    fa-icon {
        padding-right: 0.3em;
        vertical-align: middle;
    }
}

.view-document-button {
    font-size: 0.9rem;
    text-decoration: underline;
    font-family: 'Avenir Lt Std 85 Heavy';
    --color: var(--ion-color-teal);
}
