
#toolbar {
    --background: var(--ion-color-light-teal-2); 
    box-shadow:0 3px 1px -2px rgba(154, 213, 224,.18), 0 2px 2px 0 rgba(154, 213, 224,.12), 0 1px 5px 0 rgba(154, 213, 224,.1);
    --min-height: 75px;
    position: sticky;
    // border-top: 5px solid var(--ion-color-light-teal-3);
    // border-bottom: 5px solid var(--ion-color-light-teal-4);

    @media screen and (max-width: 992px) {
        display: none;
    }

    ion-item {
        --background: var(--ion-color-light-teal-2);
        button {
            background-color: #fff;
        }
    }

    ion-label {
        color: var(--ion-color-dark-teal-2);
        font-weight: 600;
    }

    .page-title {
        color: var(--ion-color-dark-teal-2);
        font-weight: 600;
    }
}