.program-card {
    display: inline-block;
    width: 20em;
    height: 22em;
    margin: 1.9em;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ccfaff+0,fffbf9+100 */
    --background: #CCFAFF;
    /* Old browsers */
    --background: -moz-linear-gradient(left, #CCFAFF 0%, #FFFBF9 82%);
    /* FF3.6-15 */
    --background: -webkit-linear-gradient(left, #CCFAFF 0%, #FFFBF9 82%);
    /* Chrome10-25,Safari5.1-6 */
    --background: linear-gradient(to right, #CCFAFF 0%, #FFFBF9 82%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CCFAFF', endColorstr='#FFFBF9', GradientType=1);
    /* IE6-9 */

    --border-radius: 14px;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
        // width: 36%;
    }

    @media screen and (max-width: 992px) {
        width: 100%;
        margin: 0em;
        height: auto;
        border-radius: 0px;
    }

    .program-image-thumbnail {
        min-height: 1px !important;
        
        @media screen and (max-width: 992px) {
            display: none;
        }

        img {
            min-height: 1px !important;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 10em;
            object-fit: cover;
        }
    }

    ion-thumbnail {
        --border-radius: 10px;
        min-width: 4.3em;
        min-height: 4.3em;
        margin-top: .5em;
    }

    .mobile-image-thumbnail {
        display: initial;

        @media screen and (min-width: 992px) {
            display: none;
        }
    }

    ion-item {
        --padding-start: 5px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ccfaff+0,fffbf9+100 */
        --background: #CCFAFF;
        /* Old browsers */
        --background: -moz-linear-gradient(left, #CCFAFF 0%, #FFFBF9 82%);
        /* FF3.6-15 */
        --background: -webkit-linear-gradient(left, #CCFAFF 0%, #FFFBF9 82%);
        /* Chrome10-25,Safari5.1-6 */
        --background: linear-gradient(to right, #CCFAFF 0%, #FFFBF9 82%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CCFAFF', endColorstr='#FFFBF9', GradientType=1);
        /* IE6-9 */

        --detail-icon-color: var(--ion-color-secondary-tint);
        --detail-icon-font-size: 1.8rem;
        --max-height: 110px;
        padding-bottom: 7px;

        .item-detail-icon {
            position: absolute !important;
            left: 92% !important;
        }
    }

    ion-label {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .program-text-container {
        margin-top: 0.3em;
        margin-left: .5em;
    }

    .program-title {
        text-align: center;
        background-color: var(--ion-color-font);
        color: #fff;
        padding: .2em;
        padding-top: .5em;
        font-weight: 300;
        font-size: 1em;
        min-height: 3.5em;

        @media screen and (max-width: 992px) {
            width: 25%;
            height: 20px;
            font-size: .7em;
            position: absolute;
            bottom: 0;
            padding: 5px;
        }
    }

    .price-text {
        position: absolute;
        bottom: -15%;
        width: 100%;
        font-size: .71rem;
        color: #fff;
        padding: .2em .2em .3em .26em;
        background-color: var(--ion-color-secondary-text);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
    }

    .program-name {
        color: var(--ion-color-text-teal);
        font-family: "Avenir Lt Std 85 Heavy";
        font-size: .7em;
        width: 85%;
        display: inline-block;
    }

    .program-text {
        color: var(--ion-color-text-teal);
        font-family: "Avenir Lt Std 65 Medium";
        font-size: 0.7em;
        padding: 0;
        height: 1.1em;
    }

    .seats-container {
        position: absolute;
        top: 38%;
        left: 76%;
        text-align: center;

        @media screen and (max-width: 400px) {
            left: 78%;
        }

        .seat-number {
            color: var(--ion-color-dark-teal-1);
            font-family: 'Avenir LT Std 85 Heavy';
            height: 1.1em;
        }

        .seat-text {
            font-size: .7em;
            line-height: 0.95em;
            color: var(--ion-color-dark-teal-1);
        }
    }

    .details-arrow {
        color: var(--ion-color-neon-teal);
        font-size: 1.3em;
        position: absolute;
        top: 43%;
        left: 93%;
    }

    .mobile-program-price {
        // top: 57.8%;
        // font-size: .75em;
        // width: 5.75em;
        // height: 1.75em;
        font-size: .8em;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        // position: absolute;
        // text-align: center;
        // background: var(--ion-color-dark-teal-2);
        // color: var(--ion-color-white);
        @media screen and (min-width: 992px) {
            display: none;
        }
        // padding-top: .25em;
    }

    .mobile-program-price-drop {
        // top: 57.8%;
        // font-size: .75em;
        // width: 5.75em;
        // height: 1.75em;
        font-size: 0.8em;
        color: #D64541;
        padding-left: 5.5em !important;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        // position: absolute;
        // text-align: center;
        // background: var(--ion-color-dark-teal-2);
        // color: var(--ion-color-white);
        @media screen and (min-width: 992px) {
            display: none;
        }
        // padding-top: .25em;
    }


    .priceContainer {
        padding-top: 0.2em;
        display: flex;
    }


    .mobile-program-price-line-through {
        // top: 57.8%;
        // font-size: .75em;
        // width: 5.75em;
        // height: 1.75em;
        font-size: .8em;
        opacity: 0.7;

        // text-decoration: line-through;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: absolute;
        // text-align: center;
        // background: var(--ion-color-dark-teal-2);
        // color: var(--ion-color-white);
        @media screen and (min-width: 992px) {
            display: none;
        }
        // padding-top: .25em;
    }

    .mobile-program-price-line-through:after {
        border-top: 1.2px solid #D64541 !important;
        position: absolute;
        content: "";
        right: 0;
        top:35%;
        left: 0;
    }


    .text-price {
        font-size: 0.9em;

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    .mobile-program-name {
        display: none;

        @media screen and (max-width: 992px) {
            color: var(--ion-color-secondary-text);
            font-size: .75rem;
            display: inline-block;
        }
    }

    .program-content {
        width: 75%;
        display: inline-block;
    }

    .program-type {
        margin: 0em;
        display: inline-flex;
        font-size: .7em;
        color: var(--ion-color-secondary-text);
    }

    // program types are fully listed, this will keep the text enough space in between line breaks
    .ion-text-wrap.sc-ion-label-ios-h,
    [text-wrap].sc-ion-label-ios-h {

        @media screen and (max-width: 767px) {
            line-height: 1;
        }
    }

    .ion-text-wrap.sc-ion-label-md-h,
    [text-wrap].sc-ion-label-md-h {
        line-height: 0.7;

        @media screen and (max-width: 767px) {
            line-height: 1;
        }
    }
}

@media screen and (max-width: 767px) {
    .sc-ion-label-md-s p {
        line-height: 13px;
    }
    .sc-ion-label-ios-s p {
        line-height: 13px;
    }
}