ion-content {
    --background: var(--ion-color-secondary-content-background);
}

ion-header {
    box-shadow:0 3px 1px -2px rgba(154, 213, 224,.18), 0 2px 2px 0 rgba(154, 213, 224,.12), 0 1px 5px 0 rgba(154, 213, 224,.1);
}

ion-header.header-md ion-toolbar:first-child {
    padding-top: 0;
}

