$customColors: (
    'primary',
    'secondary',
    'tertiary',
    'success',
    'warning',
    'danger',
    'dark',
    'medium',
    'light',
    'dark-teal',
    'green',
    'header',
    'light-teal',
    'text',
    'light-teal-button',
    'airbnb',
    'ahn',
    'fourStay',
    'sarahomestay',
    'light-blue-1',
    'light-teal-1',
    'black',
    'disabled'
);

// Rgb values are used for hover effects over buttons, tabs and links.
// In order for customColors to work, the classes' rgb values must a number value without the 'rgb()'.
// Ionic already adds the rgb function when ran with --ion-color-rgb.
@each $color in $customColors {
    .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
    }
}

:root {
    //        CLASSES
    --ion-color-primary: var(--ion-color-orange);
    // rgb for primary shade
    --ion-color-primary-rgb: 204, 97, 0;
    --ion-color-primary-contrast: var(--ion-color-white);
    --ion-color-primary-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-primary-shade: #cc6100;
    --ion-color-primary-tint: #ea7d1a;

    --ion-color-secondary: var(--ion-color-teal);
    // rgb for secondary shade
    --ion-color-secondary-rgb: 15, 158, 172;
    --ion-color-secondary-contrast: var(--ion-color-white);
    --ion-color-secondary-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-secondary-shade: #0f9eac;
    --ion-color-secondary-tint: #29bbca;

    --ion-color-tertiary: var(--ion-color-white);
    --ion-color-tertiary-rgb: var(--ion-color-white-rgb);
    --ion-color-tertiary-contrast: var(--ion-color-orange);
    --ion-color-tertiary-contrast-rgb: var(--ion-color-orange-rgb);
    --ion-color-tertiary-shade: #e0e0e0;
    --ion-color-tertiary-tint: #ffffff;

    --ion-color-success: var(--ion-color-teal);
    --ion-color-success-rgb: var(--ion-color-teal-rgb);
    --ion-color-success-contrast: var(--ion-color-white);
    --ion-color-success-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-success-shade: #109dac;
    --ion-color-success-tint: #2abaca;

    --ion-color-warning: var(--ion-color-orange);
    --ion-color-warning-rgb: var(--ion-color-orange-rgb);
    --ion-color-warning-contrast: var(--ion-color-white);
    --ion-color-warning-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-warning-shade: #cc5f00;
    --ion-color-warning-tint: #f7ae6e;

    --ion-color-danger: var(--ion-color-semantic-red);
    --ion-color-danger-rgb: var(--ion-color-semantic-red-rgb);
    --ion-color-danger-contrast: var(--ion-color-white);
    --ion-color-danger-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-danger-shade: #871f24;
    --ion-color-danger-tint: #a3393e;

    --ion-color-dark: var(--ion-color-dark-teal-1);
    --ion-color-dark-rgb: var(--ion-color-dark-teal-1-rgb);
    --ion-color-dark-contrast: var(--ion-color-white);
    --ion-color-dark-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: rgb(152, 154, 162);
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: rgb(255, 255, 255);
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: rgb(244, 244, 244);
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: rgb(0, 0, 0);
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-dark-teal: var(--ion-color-dark-teal-2);
    --ion-color-dark-teal-rgb: var(--ion-color-dark-teal-2-rgb);

    --ion-color-green: var(--ion-color-semantic-green);
    --ion-color-green-rgb: var(--ion-color-semantic-green-rgb);
    --ion-color-green-shade: #1e4825;
    --ion-color-green-tint: #1e4825;

    --ion-color-header: var(--ion-color-light-teal-1);
    --ion-color-header-contrast: var(--ion-color-dark-teal-2);

    --ion-color-light-teal: var(--ion-color-light-teal-1);
    --ion-color-light-teal-rgb: var(--ion-color-light-teal-1-rgb);
    --ion-color-text-teal: var(--ion-color-text-teal);

    --ion-color-light-teal-button: var(--ion-color-dark-teal-2);
    --ion-color-light-teal-button-rgb: var(--ion-color-mid-teal-2-rgb);
    --ion-color-light-teal-button-contrast: var(--ion-color-dark-teal-2);

    //        CORE BRAND
    --ion-color-gray: #f5f5f5;
    --ion-color-gray-rgb: rgb(245, 245, 245);

    --ion-color-dark-gray: #787878;
    --ion-color-dar-gray-rgb: rgb(120, 120, 120);

    --ion-color-orange: #e86e00;
    --ion-color-orange-rgb: rgb(232, 110, 0);

    --ion-color-teal: #11b3c4;
    --ion-color-teal-rgb: rgb(17, 179, 196);

    --ion-color-blue: #36496d;
    --ion-color-blue-rgb: rgb(54, 73, 109);
    --ion-color-blue-rgba: rgba(54, 73, 109, 0.9);

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: rgb(255, 255, 255);

    --ion-color-near-black: #2e2a26;
    --ion-color-near-black-rgb: rgb(46, 42, 38);

    //        SEMANTIC UI
    --ion-color-semantic-green: #32783d;
    --ion-color-semantic-green-rgb: rgb(50, 120, 59);

    --ion-color-semantic-red: #992329;
    --ion-color-semantic-red-rgb: rgb(153, 35, 41);
    --ion-color-semantic-red-contrast: var(--ion-color-neon-teal);

    --ion-color-semantic-light-gray: #f2f2f2;
    --ion-color-semantic-light-gray-rgb: rgb(242, 242, 242);

    //          BLUE

    --ion-color-dark-blue: #1d283b;
    --ion-color-dark-blue-rgb: rgb(29, 40, 59);

    --ion-color-mid-blue-1: #678bcf;
    --ion-color-mid-blue-1-rgb: rgb(103, 139, 207);

    --ion-color-mid-blue-2: #80acff;
    --ion-color-mid-blue-2-rgb: rgb(128, 172, 255);

    --ion-color-gray-blue-1: #1d283b;
    --ion-color-gray-blue-1-rgb: rgb(29, 40, 59);

    --ion-color-gray-blue-2: #678bcf;
    --ion-color-gray-blue-2-rgb: rgb(103, 139, 207);

    --ion-color-light-blue-1: #e6eeff;
    --ion-color-light-blue-1-rgb: rgb(230, 238, 255);

    --ion-color-light-blue-2: #f2f7ff;
    --ion-color-light-blue-2-rgb: rgb(242, 247, 255);

    --ion-color-light-blue-3: #f7faff;
    --ion-color-light-blue-3-rgb: rgb(247, 250, 255);

    --ion-color-light-blue-4: #fafcff;
    --ion-color-light-blue-4-rgb: rgb(250, 252, 255);

    //        LIGHT ORANGE
    --ion-color-light-orange-1: #e8cfba;
    --ion-color-light-orange-1-rgb: rgb(232, 207, 186);

    --ion-color-light-orange-2: #e8aa74;
    --ion-color-light-orange-2-rgb: 232, 170, 116;

    --ion-color-light-orange-3: #ffd6b2;
    --ion-color-light-orange-3-rgb: rgb(255, 214, 178);

    --ion-color-light-orange-4: #ffe4cc;
    --ion-color-light-orange-4-rgb: rgb(255, 2228, 204);

    --ion-color-light-orange-5: #fffbf9;
    --ion-color-light-orange-5-rgb: rgb(255, 251, 249);

    --ion-color-medium-orange-1: #f7a253;

    //        GRAY ORANGE
    --ion-color-gray-orange-1: #ccc1b8;
    --ion-color-gray-orange-1-rgb: rgb(204, 193, 184);

    --ion-color-gray-orange-2: #999591;
    --ion-color-gray-orange-2-rgb: rgb(153, 149, 145);

    --ion-color-gray-orange-3: #f2ece6;
    --ion-color-gray-orange-3-rgb: rgb(242, 236, 230);

    //        DARK ORANGE
    --ion-color-dark-orange-1: #7f5d40;
    --ion-color-dark-orange-1-rgb: rgb(127, 93, 64);

    --ion-color-text-orange-2: #332e29;
    --ion-color-text-orange-2-rgb: rgb(51, 46, 41);

    //        LIGHT TEAL
    --ion-color-light-teal-1: #fafeff;
    --ion-color-light-teal-1-rgb: rgb(250, 254, 255);

    --ion-color-light-teal-2: #f2feff;
    --ion-color-light-teal-2-rgb: rgb(242, 254, 255);

    --ion-color-light-teal-3: #f7feff;
    --ion-color-light-teal-3-rgb: rgb(247, 254, 255);

    --ion-color-light-teal-4: #e5fcff;
    --ion-color-light-teal-4-rgb: rgb(229, 252, 255);

    --ion-color-light-teal-5: #ccfaff;
    --ion-color-light-teal-5-rgb: rgb(204, 250, 255);

    --ion-color-light-teal-6: #bff9ff;
    --ion-color-light-teal-6-rgb: rgb(191, 249, 255);

    --ion-color-light-teal-7: #f6fafb;
    --ion-color-light-teal-7-rgb: rgba(246, 250, 251, 1)

    --ion-color-light-teal-8: #72b9c1;
    --ion-color-light-teal-8-rgba: rgba(114, 185, 193, 1);

    //        MID TEAL
    --ion-color-neon-teal: #17e8ff;
    --ion-color-neon-teal-rgb: rgb(23, 232, 255);

    --ion-color-mid-teal-1: #b9e0e6;
    --ion-color-mid-teal-1-rgb: rgb(185, 224, 230);

    --ion-color-mid-teal-2: #9ad5e0;
    --ion-color-mid-teal-2-rgb: 154, 213, 224;

    --ion-color-mid-teal-3: #52b6ca;
    --ion-color-mid-teal-3-rgb: rgb(rgb(82, 182, 202));

    --ion-color-background-teal: rgb(214, 250, 254);

    //        GRAY TEAL
    --ion-color-gray-teal-1: #e6f1f2;
    --ion-color-gray-teal-1-rgb: rgb(230, 241, 242);

    --ion-color-gray-teal-2: #dae4e5;
    --ion-color-gray-teal-2-rgb: rgb(218, 228, 229);

    --ion-color-gray-teal-3: #c2cbcc;
    --ion-color-gray-teal-3-rgb: rgb(194, 203, 204);

    --ion-color-gray-teal-4: #9dc0c4;
    --ion-color-gray-teal-4-rgb: rgb(157, 192, 196);

    --ion-color-gray-teal-5: #919899;
    --ion-color-gray-teal-5-rgb: rgb(145, 152, 153);

    //        DARK TEAL
    --ion-color-dark-teal-1: #0b6d78;
    --ion-color-dark-teal-1-rgb: rgb(11, 109, 120);

    --ion-color-dark-teal-1-contrast: #fff;
    --ion-color-dark-teal-1-contrast-rgb: rgb(255, 255, 255);

    --ion-color-dark-teal-1-shade: #e5fcff;
    --ion-color-dark-teal-1-tint: #f5f6f9;

    --ion-color-dark-teal-2: #08565e;
    --ion-color-dark-teal-2-rgb: rgb(8, 86, 94);

    --ion-color-dark-teal-3: #052e33;
    --ion-color-dark-teal-3-rgb: rgb(5, 46, 51);

    --ion-color-dark-teal-4: #2595a1;
    --ion-color-dark-teal-4-rgb: rgb(37, 149, 161);

    --ion-color-text-teal: #021719;
    --ion-color-text-teal-rgb: rgb(2, 23, 25);

    // FB BLUE FOR TEXT
    --ion-color-text-blue: #2699fb;
    // AirBnb Red Brand
    --ion-color-airbnb: #ff7674;
    --ion-color-airbnb-shade: #ff7674;
    --ion-color-airbnb-tint: #ff7674;

    // AHN Brand Red
    --ion-color-ahn: #da2c27;
    --ion-color-ahn-shade: #da2c27;
    --ion-color-ahn-tint: #da2c27;

    //sara homestay
    --ion-color-sarahomestay: #337ab7;
    --ion-color-sarahomestay-shade: #337ab7;
    --ion-color-sarahomestay-tint: #337ab7;

    --ion-color-fourStay: #ea458b;
    --ion-color-fourStay-shade: #ea458b;
    --ion-color-fourStay-tint: #ea458b;

    //

    // AirBnb Red Brand
    --ion-color-disabled: #b3bac5;
    --ion-color-disabled-shade: #b3bac5;
    --ion-color-disabled-tint: #b3bac5;
}

.black {
    color: var(--ion-color-near-black);
}

.teal {
    color: var(--ion-color-secondary);
}

.light-teal-4 {
    color: var(--ion-color-light-teal-4);
}

.mid-blue-1 {
    color: var(--ion-color-mid-blue-1);
}

.neon {
    color: var(--ion-color-neon-teal);
}

.dark-teal-1 {
    color: var(--ion-color-dark-teal-1);
}

.light-blue-1 {
    color: var(--on-color-light-blue-1);
}

.dark-teal-2 {
    color: var(--ion-color-dark-teal-2);
}

.text-teal {
    color: var(--ion-color-text-teal);
}

.dark-blue {
    color: var(--ion-color-dark-blue);
}

.orange {
    color: var(--ion-color-orange);
}

.danger {
    color: var(--ion-color-danger);
}
