ion-content {
    // --background: #08565D;
    --background: linear-gradient(to top, rgb(255, 251, 249), rgb(204, 250, 255)) !important;
}

.background-white {
    background-color: var(--ion-color-white) !important;
    --background: var(--ion-color-white) !important;
}

.hide-on {
    display: initial;
    @media screen and (max-width: 992px) {
        display: none;
    }
}

.hide-on-small {
    display: initial;
    @media screen and (max-width: 457px) {
        display: none;
    }
}

.show-on-small {
    display: none;
    @media screen and (max-width: 457px) {
        display: initial;
    }
}

.m-top {
    margin-top: 1em;
}

.m-bottom {
    margin-bottom: 1em;
}

.m-auto {
    margin: auto !important;
}

.p-left {
    padding-left: 1.5em;
}

.p-left-sm {
    padding-left: 0.5em;
}

.col-left {
    background-color: var(--ion-color-light-teal-1);
    display: initial;
    border-left: 2px solid var(--ion-color-light-blue-2);

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.col-right {
    background-color: white;
}

.title-container {
    background-color: var(--ion-color-light-teal-2);
    font-family: 'Avenir LT Std 95 Black';
    width: 100%;
    height: auto;
    padding: 0.5em;

    .title {
        color: var(--ion-color-dark-teal-2);
        padding: 0.2em;
        font-size: 1.1em;

        @media screen and (max-width: 992px) {
            font-size: 1em;
        }
    }
}

.intro-title {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;

    ion-label {
        font-size: 1.25em;
        color: var(--ion-color-dark-blue);

        @media screen and (max-width: 767px) {
            font-size: 1.1em;
        }
    }
}

// Blue squiggly from dashboard and contact advisor components
.squiggly {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;

    @media screen and (max-width: 767px) {
        width: 70%;
    }
}

.line-squiggle {
    margin-top: 1.5em;
}

.more-actions-container {
    position: absolute;
    right: -2%;
    top: 0%;
    z-index: 2;

    @media screen and (min-width: 1199px) {
        display: none;
    }

    .more-actions-button {
        ion-icon {
            font-size: 1.2em;
        }
    }

    .compress {
        margin: 0;
        padding: 0;
    }
}

.card-header-starttime {
    @media screen and (max-width: 576px) {
        padding-top: 5px;
        padding-right: 5px;
        padding-left: 5px;
    }
}

.application-starttime {
    width: 50%;
    background: var(--ion-color-light-orange-5);
    border: 1px solid var(--ion-color-light-orange-2);
    color: var(--ion-color-text-orange-2);
    border-radius: 5px;
    width: 60%;
    margin: auto;
    padding: 0.4em 0.2em 0.2em 0.2em;

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.buttons-divider {
    background: var(--ion-color-light-orange-3);
    width: 100%;
}

.program-mobile-image {
    display: none;
    @media screen and (max-width: 1199px) {
        display: initial;
        --padding-start: 0px;
    }

    .mobile-program-name {
        @media screen and (max-width: 415px) {
            font-size: 0.7em;
        }
    }
}

.desktop-image-slider {
    display: initial;
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.program-details-list-desktop {
    display: initial;
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.mobile-thumbnail-images {
    --border-radius: 10px;
    height: 6em;
    width: 6.5em;
    @media screen and (max-width: 576px) {
        height: 4em;
        width: 4.5em;
    }
}

.mobile-program-tags {
    display: none;

    @media screen and (max-width: 1199px) {
        display: initial;
    }
}
